<template>
  <div class="ticket-wrapper">
    <event-ticket-header-view
      :title="name"
      :ticket-id="id"
      :price="price"
      :currency="currency"
      :pretax-price-visible="pretaxPriceVisible"
      :pretax-price-highlighted="pretaxPriceHighlighted"
      :event-tickets-vat="eventTicketsVat"
      :ticket-view-type="ticketViewType"
    ></event-ticket-header-view>
    <event-ticket-description-view :description="description" :ticket-view-type="ticketViewType" :ticket-id="ticket.id">
      <button
        v-on:click="$emit('click', id)"
        class="button is-purchase is-primary has-text-weight-bold"
        :class="getCustomTicketButtonBgClass"
        :disabled="buttonDisabled"
      >
        {{ buttonText }}
      </button>
      <div class="bottom-text" v-if="bottomText">{{ bottomText }}</div>
    </event-ticket-description-view>
  </div>
</template>

<script>
import EventTicketHeaderView from "@/web/components/eventtickets/EventTicketHeaderView";
import EventTicketDescriptionView from "@/web/components/eventtickets/EventTicketDescriptionView";
import { mapState } from "vuex";
import dayjs from "@/shared/utils/day";

export default {
  name: "EventTicketView",
  components: { EventTicketHeaderView, EventTicketDescriptionView },

  props: {
    ticket: {
      type: Object,
      required: true,
    },

    disabled: {
      type: Boolean,
      required: true,
    },

    pretaxPriceVisible: {
      type: Boolean,
      default: false,
    },

    pretaxPriceHighlighted: {
      type: Boolean,
      default: false,
    },

    eventTicketsVat: {
      type: Number,
      required: true,
    },

    customDescription: {
      type: String,
      required: false,
    },
    ticketViewType: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapState(["eventId", "timezone"]),

    isSurvivalRaceKidsEvent() {
      return this.eventId === 1129;
    },

    isMebleEvent() {
      return this.eventId === 1343 || this.eventId === 245;
    },

    isProExpertEvent() {
      return this.eventId === 1419 || this.eventId === 118;
    },

    isXEvent() {
      return this.eventId === 1440;
    },

    isFlukeEvent() {
      return this.eventId === 1507;
    },

    isPismEvent() {
      return this.eventId === 1312;
    },

    isDtsEvent() {
      return this.eventId === 1411;
    },

    isRzecommerce2024Event() {
      return this.eventId === 1643 || this.eventId === 104;
    },

    isRzecommerce2024PrimaryTicket() {
      return this.ticket.id === 923 || this.ticket.id === 41;
    },

    isArawuEvent() {
      return this.eventId === 1426;
    },

    isTutloEvent() {
      return this.eventId === 1408;
    },

    isEvExperienceEvent() {
      return this.eventId === 1425;
    },

    isArawEvent() {
      return this.eventId === 1509;
    },

    isTargiSpecjalEvent() {
      return this.eventId === 1575;
    },

    isUpgrade2024Event() {
      return this.eventId === 1530;
    },

    isVCEvent() {
      return this.eventId === 1604;
    },

    bottomText() {
      return null;
    },

    getCustomTicketButtonBgClass() {
      return {
        'ev-experience-custom-button-bg': this.isEvExperienceEvent && (this.ticket.id === 515 || this.ticket.id === 518),
        'green-energy-tricity-custom-button-bg': this.ticket.id === 580,
        'upgrade-2024-event-button-bg': this.isUpgrade2024Event,
        'rzecommerce-2024-event-button-bg': this.isRzecommerce2024Event,
        'primary': this.isRzecommerce2024Event && this.isRzecommerce2024PrimaryTicket,
      };
    },

    price() {
      if (this.isEvExperienceEvent && this.ticket.id === 518) {
        return this.ticket.price * 3 * 0.01;
      }
      return this.ticket.price * 0.01;
    },

    name() {
      if (this.isSurvivalRaceKidsEvent) {
        const categoryWithDate = this.ticket.name.split(".");
        return dayjs(categoryWithDate[1]).tz(this.timezone).format("dddd DD.MM");
      } else {
        return this.ticket.name;
      }
    },
    // price() {
    //   return this.ticket.price * 0.01;
    // },
    currency() {
      return this.ticket.currency;
    },
    isSoldOut() {
      return this.ticket.tickets_left !== null && this.ticket.tickets_left === 0;
    },
    description() {
      return (this.customDescription && this.$t(`custom.${this.customDescription}`)) || this.ticket.description;
    },
    id() {
      return this.ticket.id;
    },
    buttonText() {
      if (this.isPismEvent) {
        return this.$t("custom.pism_get_ticket");
      } else if (this.isFlukeEvent) {
        return this.$t("custom.fluke_join_community");
      } else if (this.isDtsEvent) {
        return this.$t("custom.dts_setup_account");
      } else if (this.isProExpertEvent) {
        return this.$t("custom.ped_get_ticket_2");
      } else if (this.isMebleEvent || this.isXEvent || this.isFlukeEvent) {
        return this.$t("custom.meble_register");
      } else if (this.isTutloEvent) {
        return this.$t("custom.tutlo_buy_ticket");
      } else if (this.isTargiSpecjalEvent) {
        return this.$t("custom.targi_specjal_get_ticket");
      } else if (this.isArawuEvent) {
        return this.$t("custom.arawu_get_ticket");
      } else if (this.isArawEvent && this.ticket.id === 613) {
        return this.$t("ticket.buy_ticket");
      } else if (this.isVCEvent) {
        return this.$t("custom.vc_get_ticket");
      } else if (this.disabled) {
        return this.$t("ticket.ticket_bought");
      } else if (this.price === 0) {
        return this.$t("ticket.get_free_ticket");
      } else if (this.isSoldOut) {
        return this.$t("ticket.ticket_sold_out");
      } else {
        return this.$t("ticket.buy_ticket");
      }
    },
    buttonDisabled() {
      return this.disabled || this.isSoldOut;
    },
  },
};
</script>

<style scoped lang="scss">
.ticket-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.ev-experience-custom-button-bg {
  background-color: #02a2ce;
}

.ev-experience-custom-button-bg:hover {
  background-color: #0192ba;
}

.green-energy-tricity-custom-button-bg {
  background-color: #fbba00;
}

.green-energy-tricity-custom-button-bg:hover {
  background-color: #f8bb11;
}

.upgrade-2024-event-button-bg {
  background-color: #73E6B7;
}

.rzecommerce-2024-event-button-bg {
  background-color: #fff;
  color: #5F23F0;
  border-radius: 12px;

  &:hover {
    background-color: #491ABF;
    color: #fff;
  }

  &.primary {
    color: var(--primary-color);

    &:hover {
      background-color: var(--primary-color-hover);
      color: #fff;
    }
  }
}

.upgrade-2024-event-button-bg:hover {
  background-color: #60d0a1;
}

.bottom-text {
  font-size: 12px;
  margin-top: 20px;
  color: #d3d3d3;
  text-align: center;
}

.is-purchase {
  margin-top: 16px;
  width: 100%;
  flex: 0 0 auto;
}
</style>
